<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getOptionReport"
    ></select-shop>

    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
            <h2 class="mb-0 font-weight-normal">
            <span id="optionalTitle">{{ $t('report.txtOptionalsales') }}</span>
          </h2>
          <HelpButton :isHeader="false" class="ml-2"></HelpButton>
          </div>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3 col-4 text-right text-success"
            >
              <CButton id="optionalExportButton" v-on:click="exportOptionReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <sortdata
                v-on:getSort="setSort"
                :sort_value="sort_value"
                :sortdata="sortdata"
              />
              <hr />
              <DataTable
                id="optionalDatatable"
                style="white-space:nowrap"
                :items="items"
                :fields="fields"
                hover
                border
                :loading="loading"
              >
              <template #onhandQty="{ item }">
                    <td v-if="item.onhandQty < 0.00" class="text-right text-danger">{{ item.onhandQty }}</td>
                    <td v-else class="text-right text-dark">{{ item.onhandQty }}</td>
                </template>
              </DataTable>
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getOptionReport">
          </pagination>
          
          
          
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import Sortdata from '@/containers/SortData'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import util from '@/util/util'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: {
    Pagination,
    Sortdata,
    DataTable,
    HelpButton
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      sortOrder: 'asc',
      sortdata: 'sortByBillAmountMin',
      sortnum: false,
      sort_value: {
        
        sortByBillAmountMin: 'sortByBillAmountMin',
        sortByBillAmountMax: 'sortByBillAmountMax',
      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata
        if (sortdata == 'sortByBillAmountMin' || sortdata == 'sortByBillAmountMax') {
          sortdata = 'quantity'
        }
        return sortdata
      },
      set(newValue) {
        return newValue
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t('#'),
          _classes: 'text-center text-dark font-weight-normal',
          _style: 'width:10%',
        },
        // {
        //   key: 'optionCategory',
        //   label: this.$i18n.t('txtOptionCategory'),
        //   _style: 'width:25%',
        //   _classes: 'text-center text-dark font-weight-normal'
        // },
        { key: 'optionName', label: this.$i18n.t('txtOption'), _classes: 'text-left text-dark font-weight-normal'},
        {
          key: 'salesQty',
          label: this.$i18n.t('billAmount'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:15%',
        },
        // {
        //   key: 'stdCost',
        //   label: this.$i18n.t('stdCost'),
        //   _classes: 'text-center text-dark font-weight-normal',
        //   _style: 'width:10%',
        // },
        {
          key: 'exceedUc',
          label: this.$i18n.t('exceedUc'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:15%',
        },
        {
          key: 'sales',
          label: this.$i18n.t('sales'),
          _classes: 'text-right text-dark font-weight-normal',
          _style: 'width:15%',
        },
      ]
    },
    items() {
    let data = this.data || []
    let detail = []

    for (let i = 0; i < data.length; i++) {
      const item = data[i]
      let price = ''
      if (item.price === null) {
          price = '0.00'
        } else {
          price = util.convertCurrency(item.price)
        }
      detail.push({
        id: i + 1,
        // 'optionCategory' : item.itemInGroupId,
        'optionName': item.optionItemName,
        'salesQty': item.quantity,
        'exceedUc': price,
        // 'exceedUc': item.total,
        'sales': (item.total).toFixed(2),
        
      })
    }
    return detail
  },
  },
  created() {
    this.getOptionReport()
  },
  methods: {
    setSort(value) {
      this.sortdata = value
      this.getOptionReport()
    },
    getOptionReport(page = 1) {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      let sortOrder = ''
      if (this.sortdata == 'sortByBillAmountMin') {
        sortOrder = 'asc'
      } else if (
        this.sortdata == 'sortByBillAmountMax' 
      ) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        sortattr: this.sortAttr,
        sortorder: sortOrder,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = true
      axios({
        url: '/receipt/v1.0/optionitems/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportOptionReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/receipt/v1.0/optionitems/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานยอดขายตามตัวเลือก.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="../../../src/assets/styles/sortCss.css"></style>